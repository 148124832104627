<template>
    <!--  <img alt="Vue logo" src="./assets/logo.png"> -->
  <v-app>

  <v-app-bar :elevation="2" color="black">
     <v-row>
          <v-divider class="ms-10" inset vertical></v-divider> 
          <h1 style="margin: 5px;">Chce<span class="green">zamieszkac</span></h1>
    
          <v-col class="text-right">
             <router-link to="/new-offer"><v-btn value="recent" prepend-icon="add" class="bg-green">Dodaj ogłoszenie</v-btn> </router-link>
          <v-divider class="ms-5" inset vertical></v-divider> 

          </v-col>
     </v-row>
  </v-app-bar>

  <router-view/>

  </v-app>

</template>

<script>

import { useRouter, useRoute } from 'vue-router'
import { accountStore } from '@/stores/account.ts'
import { watch } from "vue"


export default {
  name: 'App',
  setup() {
    const account = accountStore()
    const router = useRouter()
    const route = useRoute()
 
    watch(
      () => route.fullPath,
      async () => {
        if(account.token==null){
            router.push({path:'/login'})
        }
      }
    );

    if(account.token==null){
            router.push({path:'/login'})
    }

    return { account, router }
  },
  methods:{
  },
  computed(){
      this.isLogged()
  }
}

</script>
<style>
#app {
  font-family: 'GT America Trial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.green { color:#00b54b; }
</style>
