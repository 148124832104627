import { defineStore } from 'pinia'
import axios from 'axios'

export const accountStore = defineStore('account', {
	state: () => ({ email:null,token:null }),
	getters: {
			getToken: (state) => state.token,
			getEmail: (state) => state.email
	},
	actions:{
			async login(email, password) {
				this.email = email
				await axios.post('https://api1.crslabs.com/rpc/login', {'email':email, 'password':password}, {
				}).then((response) => {
                    try{
				    	const data = JSON.parse(response.data)
                        if(!data.jwt_token){
                            return;
                        }
                        this.token = data.jwt_token
                    }catch(e){}
				});
                return this.token;
			}
	}
})
