<template>
<div class="d-flex align-center justify-center" style="height: 90vh">
        <v-sheet width="400" class="mx-auto">
            <v-form fast-fail @submit.prevent="login">
                <v-text-field v-model="email" label="email"></v-text-field>

                <v-text-field v-model="password" type="password" label="password"></v-text-field>
                <a href="#forgot-password" class="text-body-2 font-weight-regular">Zapomniałeś hasła?</a>

                <v-btn type="submit" color="primary" block class="mt-2">Sign in</v-btn>

            </v-form>
            <div class="mt-2">
                <p class="text-body-2">Nie masz konta? <a href="#/register">Rejestracja</a></p>
            </div>
        </v-sheet>
</div>
</template>

<script>
import { useRouter } from 'vue-router'

import { accountStore } from '@/stores/account.ts'

export default {
  name: 'LoginPage',
  props: {
    msg: String
  },
  setup() {
    const account = accountStore();
    const router = useRouter()
    return { account, router };
  },
  data () {
    return {
		api:'https://api1.crslabs.com/',
		email: "",
		password: "",
        debug:""
    }
  },
  mounted () {
  },
  methods:{
		test(){
            this.debug = this.account.getToken
		},
		login() {
            this.account.login(this.email, this.password).then((token)=>{
//                console.log(token)
                if(token){
                    this.router.push({ path: '/' })
                }
            });
		}
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
