import { createApp } from 'vue'
import { createPinia } from 'pinia'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import axios from 'axios'
import VueAxios from 'vue-axios'

import colors from 'vuetify/util/colors'

import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure your project is capable of handling css files
import { aliases, md } from 'vuetify/iconsets/md'

const vuetify = createVuetify({
    components,
    directives,
    theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: colors.green.darken1, // #E53935
          secondary: colors.green.lighten4, // #FFCDD2
        }
      },
     },
    },
    icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
    },
  },
})

const pinia = createPinia()

import App from './App.vue'

import * as VueRouter from 'vue-router';

import HelloWorld from './components/HelloWorld.vue'
import LoginPage from './components/LoginPage.vue'


const routes = [
  { path: '/', component: HelloWorld },
  { path: '/login', component: LoginPage },
]

const router = VueRouter.createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: VueRouter.createWebHashHistory(),
  routes, // short for `routes: routes`
})

createApp(App).use(vuetify).use(pinia).use(VueAxios, axios).use(router).mount('#app')


