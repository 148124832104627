<template>

    <v-container style="padding-top: 100px;">

    <v-row>


    <v-btn @click="update()">UPDATE</v-btn>
<v-btn @click="add()">ADD</v-btn>
<v-btn @click="test()">TEST</v-btn>

{{ debug }}

    </v-row>
    <v-row>

    <v-list>
      <v-list-subheader>REPORTS</v-list-subheader>

      <v-list-item
        v-for="(item) in items"
        :key="item.id"
        :value="item.task"
        color="primary"
        rounded="xl"
      >
        <template v-slot:prepend>
          <v-icon :icon="item.icon"></v-icon>
        </template>

        <v-list-item-title v-text="item.task"></v-list-item-title>
      </v-list-item>
    </v-list>

  </v-row>
</v-container>


</template>

<script>
import axios from 'axios'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
		items: null,
		api:'https://api1.crslabs.com/',
		token:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoidG9kb191c2VyIiwiZW1haWwiOiJqb2huIn0.fyDxHfZjLicob5nbS3Ij8-NNa1ft_nh5NJ8JAIsghVc',
		email: "",
		password: "",
        debug:""
    }
  },
  mounted () {
	this.get()
  },
  methods:{
		test(){
		},
		get(){
			axios.get(this.api+'todos').then(response => (this.items = response['data']))
		},
		update(){
				axios.patch(this.api+'todos', {'tasl':'new'}, {
				headers: { 'Authorization': 'bearer '+this.token }
			}).then((response) => {
				console.log(response)
			}).catch(function (error) {
				console.log(error);
			});
		},
		add(){
			axios.post(this.api+'todos', {'task':'new'}, {
			headers: { 'Authorization': 'bearer '+this.token }
			}).then((response) => {
				this.get()
				console.log(response)
			});
		}
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
